import React, { useState } from 'react';

import NavBar from './NavBar';

const Header = ({ navigators }) => {
  const [navStatus, setNavStatus] = useState(1);
  const updateNavStatus = (status) => {
    setNavStatus(status);
  };

  return (
    <header className={`header ${navStatus ? 'active' : ''}`}>
      <NavBar getNavStatus={updateNavStatus} navigators={navigators} />
    </header>
  );
};

export default Header;
