import React from 'react';

export default function TitleGroup({customClass, title, subtitle, rtl = false}) {
  return (
    <div className={`text-container ${customClass || ''} ${rtl ? 'text-container-rtl' :''}`}>
      <div className='text-title'>{title}</div>
      <div className='text-subtitle'>
        <span>{subtitle}</span> <i className='iconfont icon-arrow-rb'></i>
      </div>
    </div>
  );
}
