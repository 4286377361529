import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import TitleGroup from '../components/TitleGroup';

export default function Contact({ email, phone, contactName }) {
  return (
    <section id='contact' name='contact' className='contact'>
      <div className='contact-bg'>
        <StaticImage className='contact-bg-image' placeholder='none' src='../assets/images/contact.png' alt='' />
      </div>
      <div className='contact-container'>
        <TitleGroup customClass='contact-title' title='Contact Us' subtitle='联系我们' />

        <div className='contact-content'>
          <div className='contact-item'>
            邮箱: <a href={`mailto:${email}`}>{email}</a>
          </div>
          <div className='contact-item'>
            手机：<a href={`tel:${phone}`}>{phone}</a>（{contactName}）
          </div>
          <div className='contact-item'>位置：上海市静安区武定路555号202室</div>
        </div>
        <div className='contact-content'>
          <div className='contact-item'>微信小程序： 小岛凸凸Tootools</div>
          <div className='contact-item'>
            微信公众号：
            <span>
              <i className='iconfont icon-wechat'></i>
            </span>
            小岛凸凸
            {/* <span>
              <i className='iconfont icon-weibo'></i>
            </span>
            <span>
              <i className='iconfont icon-xiaohongshu'></i>
            </span> */}
          </div>
          <div className='contact-item'></div>
        </div>
        <footer className='contact-footer'>
          <div className='contact-footer-item'>© 2021 上海瑞杰印信息科技有限公司</div>
          <div className='contact-footer-item'>
            <a href='http://beian.miit.gov.cn/' target='_blank'>
              沪ICP备2021029977号-1
            </a>
          </div>
          <div className='contact-footer-item'>
            <a target='_blank' href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006515'>
              <StaticImage className='' placeholder='none' src='../assets/images/national_emblem.png' alt='' />
              &nbsp;沪公网安备 31010602006515号
            </a>
          </div>
          <div className='contact-footer-item contact-footer-item-warp'>
            {/* <a href="javascript:;">联系我们</a> */}
            <span>法律声明</span>
            <span>隐私保护</span>
          </div>
        </footer>
      </div>
    </section>
  );
}
