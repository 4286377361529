import React, { useState } from 'react';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Link as GLink } from 'gatsby';

const NavBar = ({ getNavStatus, navigators }) => {
  const [menuStatus, setMenuStatus] = useState(false);

  // window.onscroll = () => {
  //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //   navigators.forEach(({ id }) => {
  //     if (scrollTop >= document.getElementById(id)?.offsetTop) {
  //       handleActive(id);
  //     }
  //     console.log(scrollTop);
  //   });
  // };

  // const scrollTo = (id) => {
  //   const top = document.getElementById(id)?.offsetTop;
  //   window.scroll({
  //     top,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // };

  // const handleActive = (id) => {
  //   const newNav = navigators.map((nav) => {
  //     if (nav.id === id) {
  //       nav.isActive = true;
  //     } else {
  //       nav.isActive = false;
  //     }
  //     return nav;
  //   });
  //   setNavigators(newNav);
  // };

  // const handleNavClick = (id) => {
  //   scrollTo(id);
  //   handleActive(id);
  // };
  const handleSetInactive = () => {
    getNavStatus(0);
  };

  const handleSetActive = (id) => {
    getNavStatus(1);
  };

  const handleMenu = () => {
    setMenuStatus(!menuStatus);
  };

  return (
    <React.Fragment>
      <nav className='nav-pc'>
        <ul>
          <li style={{ width: `${100 / 6}%` }}>
            <GLink activeClass='active' to='/' spy={true} smooth={true} duration={250} onSetActive={() => handleSetActive()} onSetInactive={() => handleSetInactive()}>
              <i className='iconfont icon-tootools icon-logo'></i>
            </GLink>
          </li>
          {navigators.map(({ id, name }, index) => (
            <li key={index} style={{ width: `${100 / 6}%` }}>
              <Link activeClass='active' to={id} spy={true} smooth={true} duration={250}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <nav className='nav-m'>
        <div className='nav-bar'>
          <i className={`iconfont ${menuStatus ? 'icon-close' : 'icon-menu'} nav-menu-btn`} onClick={() => handleMenu()}></i>
          <GLink to='/' spy={true} smooth={true} duration={250}>
            <i className='iconfont icon-tootools icon-logo'></i>
          </GLink>
        </div>
        <ul className={`ul-m ${menuStatus ? 'menu-active' : ''}`}>
          {navigators.map(({ id, name }, index) => (
            <li key={index}>
              <Link to={id} spy={true} smooth={true} duration={250} onClick={() => handleMenu()}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
